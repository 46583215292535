<template>
  <div class="wrapper">
    <div class="fondoGrisClaro">
      <video
        id="myVideo"
        autoplay
        muted
        loop
      >
        <source
          src="../assets/hd-video.mp4"
          type="video/mp4"
        >
      </video>
      <div class="content">
        <button
          id="myBtn"
          @click="myFunction()"
        >
          <font-awesome-icon :icon="icon" />
        </button>
      </div>
    </div>
    <b-container
      id="formulario"
      class="mt-5 mb-5"
    >
      <b-row>
        <b-col
          class="contact-form agente"
          lg="6"
          data-aos="fade-in"
          data-aos-delay="400"
        >
          <form
            v-if="!sentSuccess"
            class="contact3-form validate-form"
            @submit.prevent="onSubmit"
          >
            <h2>{{ $t('message_form.Déjanos_tus_datos') }}</h2>
            <b-row>
              <b-col lg="6">
                <div class="form-group">
                  <label class="text-white">¿Cual es tu nombre?</label>
                  <b-input
                    v-model="form.name"
                    type="text"
                    placeholder="Tu nombre"
                    class="form-control"
                    required
                  />
                </div>
              </b-col>
              <b-col lg="6">
                <div class="form-group">
                  <label class="text-white">Tu mejor mail</label>
                  <b-input
                    v-model="form.email"
                    type="email"
                    placeholder="Tu mail"
                    class="form-control"
                    required
                  />
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="6">
                <div class="form-group">
                  <label class="text-white">Contactar</label>
                  <div class="input-group">
                    <label class="text-white mr-2"><input
                      v-model="form.time"
                      type="radio"
                      name="radio"
                      :value="$t('message_form.mañana')"
                      required
                    > {{ $t('message_form.mañana') }}</label>
                    <label class="text-white"><input
                      v-model="form.time"
                      type="radio"
                      name="radio"
                      :value="$t('message_form.tarde')"
                      required
                    > {{ $t('message_form.tarde') }} </label>
                  </div>
                </div>
              </b-col>
              <b-col lg="6">
                <div class="form-group">
                  <label class="text-white">{{ $t('convenio.Cual_es_tu_situación') }}</label>
                  <div class="input-group">
                    <label class="text-white mr-2"><input
                      v-model="form.situation"
                      type="radio"
                      name="radio0"
                      :value="$t('message_form.empresa')"
                      required
                    > {{ $t('message_form.empresa') }} </label>
                    <label class="text-white"><input
                      v-model="form.situation"
                      type="radio"
                      name="radio0"
                      :value="$t('message_form.practicar')"
                      required
                    > {{ $t('message_form.practicar') }} </label>
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="6">
                <div class="form-group">
                  <select
                    v-model="form.option"
                    class="form-control"
                    required
                  >
                    <option
                      value=""
                      disabled
                      selected
                    >
                      {{ $t('message_form.opt_edu_noselected') }}
                    </option>
                    <option :value="$t('message_form.opt1')">
                      {{ $t('message_form.opt1') }}
                    </option>
                    <option :value="$t('message_form.opt2')">
                      {{ $t('message_form.opt2') }}
                    </option>
                    <option :value="$t('message_form.opt3')">
                      {{ $t('message_form.opt3') }}
                    </option>
                    <option :value="$t('message_form.opt4')">
                      {{ $t('message_form.opt4') }}
                    </option>
                    <option :value="$t('message_form.opt5')">
                      {{ $t('message_form.opt5') }}
                    </option>
                    <option :value="$t('message_form.opt6')">
                      {{ $t('message_form.opt6') }}
                    </option>
                    <option :value="$t('message_form.opt7')">
                      {{ $t('message_form.opt7') }}
                    </option>
                  </select>
                </div>
              </b-col>
              <b-col lg="6">
                <div class="form-group">
                  <select
                    v-model="form.option2"
                    class="form-control"
                    required
                  >
                    <option
                      value=""
                      disabled
                      selected
                    >
                      {{ $t('message_form.opt_for_noselected') }}
                    </option>
                    <option :value="$t('message_form.opti1')">
                      {{ $t('message_form.opti1') }}
                    </option>
                    <option :value="$t('message_form.opti2')">
                      {{ $t('message_form.opti2') }}
                    </option>
                    <option :value="$t('message_form.opti3')">
                      {{ $t('message_form.opti3') }}
                    </option>
                    <option :value="$t('message_form.opti4')">
                      {{ $t('message_form.opti4') }}
                    </option>
                  </select>
                </div>
              </b-col>
            </b-row>
            <div class="form-group">
              <label class="text-white">¿A qué numero te llamamos?</label>
              <b-input
                v-model="form.number"
                type="tel"
                placeholder="Su teléfono"
                class="form-control"
                required
              />
            </div>
            <div class="form-group">
              <label class="text-white">{{ $t('message_form.mensaje') }}</label>
              <b-textarea
                v-model="form.message"
                class="form-control"
              />
            </div>
            <div class="form-group">
              <ul class="list">
                <li>{{ $t('convenio.RGPD_youcan') }} <a href="/aviso-legal-privacidad/">{{ $t('convenio.RGPD_policy') }}</a></li>
                <li><strong>{{ $t('convenio.RGPD_responsible_catp') }}</strong>: {{ $t('convenio.RGPD_responsible_answ') }}</li>
                <li><strong>{{ $t('convenio.RGPD_finalidad') }}</strong>: {{ $t('convenio.RGPD_info') }}</li>
                <li><strong>{{ $t('convenio.RGPD_almacenamiento') }}</strong>: {{ $t('convenio.RGPD_almacenamiento_anws') }}</li>
                <li><strong>{{ $t('convenio.RGPD_right') }}</strong>: {{ $t('convenio.RGPD_right_anws') }} <a href="https://www.websincoste.com/page/aviso-legal-privacidad/">{{ $t('convenio.RGPD_limits') }}</a></li>
              </ul>
              <div class="text-white">
                <input
                  id="RGPD_OPTIN"
                  v-model="form.checked"
                  type="checkbox"
                  class="check"
                  required
                >
                {{ $t('convenio.He_leído') }}<a
                  href="/aviso-legal-privacidad/"
                  target="_blank"
                >{{ $t('convenio.políticas_de_privacidad') }}</a>
              </div>
            </div>
            <div class="form-group">
              <button
                id="send-btn"
                class="sib-form-block__button"
                type="submit"
              >
                {{ $t('common_contact_form.send') }}
              </button>
              <h3
                v-if="sentFailed"
                style="color: #ff9ca6;"
              >
                Error: ¡Inténtalo de nuevo!
              </h3>
            </div>
          </form>
          <div
            v-else-if="sentSuccess"
            class="send-success"
          >
            <h2>{{ $t('common_contact_form.thanks_message') }}</h2>
            <h3 style="color:#fff;">
              {{ $t('common_contact_form.will_contact') }}
            </h3>
            <div
              class="contact-info text-center mt-4"
              style="color:#fff"
            >
              <h4>Puedes contactarme por</h4>
              <div class="contact-nfo">
                <font-awesome-icon
                  icon="phone"
                  class="phone-icon"
                />
                <a
                  href="tel:+34928354668"
                  style="color:#fff"
                >+34 928 354 668</a>
              </div>
            </div>
          </div>
        </b-col>
        <b-col lg="1" />
        <b-col
          lg="5"
          class="right-text"
        >
          <h2
            data-aos="fade-down"
            data-aos-duration="500"
          >
            {{ $t('convenio.CONVENIO_DE_PRÁCTICAS_EN') }}
          </h2>
          <h1
            style=""
            data-aos="fade-down"
            data-aos-duration="300"
          >
            {{ $t('convenio.Empresas') }}
          </h1>
          <p>
            {{ $t('convenio.En_AICAD_las_prácticas') }}
          </p>
        </b-col>
      </b-row>
    </b-container>
    <b-container
      id="cards"
      class="mt-5 mb-5"
    >
      <b-row>
        <b-col
          lg="4"
          data-aos="fade-down"
          data-aos-delay="300"
        >
          <div class="text-container">
            <h1>
              {{ $t('business_break.crisis') }}
            </h1>
            <h2>
              {{ $t('business_break.que') }}
            </h2>
          </div>
        </b-col>
        <b-col
          lg="4"
          data-aos="fade-down"
          data-aos-delay="200"
        >
          <div class="text-container">
            <h1>
              {{ $t('business_break.crecimiento') }}
            </h1>
            <h2>
              {{ $t('business_break.ya') }}
            </h2>
          </div>
        </b-col>
        <b-col
          lg="4"
          data-aos="fade-down"
          data-aos-delay="300"
        >
          <div class="text-container">
            <h1>
              {{ $t('business_break.nuevo') }}
            </h1>
            <h2>
              {{ $t('business_break.alo') }}
            </h2>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <div class="sect3 mt-5 mb-5 pb-5 pt-5">
      <b-container>
        <b-row>
          <b-col
            lg="4"
            md="4"
            sm="12"
            class="left"
          >
            <img
              loading="lazy"
              src="../assets/img/business_break/aicad_el_futuro.jpg"
              alt="aicad_el _futuro_de_las_asesorias_en_la_era_digital"
            >
          </b-col>
          <b-col
            lg="8"
            md="8"
            sm="12"
            class="right"
          >
            <h3>{{ $t('business_break.h1') }}</h3>
            <p class="p-light">
              {{ $t('business_break.p1') }}
            </p>
            <p class="p-light">
              {{ $t('business_break.p2') }}
            </p>
            <p class="p-light">
              {{ $t('business_break.p3') }}
            </p>
            <p class="p-bold">
              {{ $t('business_break.p4') }}
            </p>
            <p class="p-light">
              {{ $t('business_break.p5') }}
            </p>
            <p class="p-light">
              {{ $t('business_break.p6') }}
            </p>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="sect4 mb-5">
      <h1 class="h1-grey">
        {{ $t('business_break.EVENTOS') }}
      </h1>
      <h1 class="h1-sign">
        {{ $t('business_break.empresariales') }}
      </h1>
      <b-container class="gallery">
        <b-row>
          <b-col
            v-for="aicad in department"
            :key="aicad.name"
            lg="4"
          >
            <img
              loading="lazy"
              alt="Team member"
              class="image"
              :src="getImgUrl(aicad.image)"
              rel="nofollow"
            >
            <div class="overlay">
              <div class="text">
                {{ aicad.name }}
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
export default {
    name: 'BusinessBreak',
    data() {
        return {
            icon: 'pause',
            s1subtitlepurl: 'http://planetajob.es',
            department: [
                {
                    image: '6.jpg',
                    name: 'Alicante'
                },
                {
                    image: '2.jpg',
                    name: 'Bilbao'
                },
                {
                    image: '3.jpg',
                    name: 'Castellon'
                },
                {
                    image: '4.jpg',
                    name: 'Galicia'
                },
                {
                    image: '1.jpg',
                    name: 'Madrid'
                },
                {
                    image: '5.jpg',
                    name: 'Zaragoza'
                }
            ],
            checkedNames: [],
            form: {
                name: '',
                email: '',
                number: '',
                option: '',
                option2: '',
                situation: '',
                time: '',
                message: '',
                checked: ''
            },
            status: 'not_accepted',
            sentSuccess: false,
            sentFailed: false
        };
    },
    methods: {
        myFunction() {
            var video = document.getElementById('myVideo');
            if (video.paused) {
                video.play();
                this.icon = 'pause';
            } else {
                video.pause();
                this.icon = 'play';
            }
        },
        getImgUrl(pic) {
            return require('../assets/img/business_break/' + pic);
        },
        onSubmit() {
            event.preventDefault();
            this.axios
                .post('https://www.websincoste.com/index.php', {
                    _nonce: 'f9d8d3d927baca5e53d95c32b41f6f00',
                    action: 'send_universal_form',
                    serialize: [{ name: 'Form Type', value: 'Business Break Form' }, { name: 'Nombre', value: this.form.name }, { name: 'Email', value: this.form.email }, { name: 'Telefono', value: this.form.number }, { name: 'Opcion 1', value: this.form.option }, { name: 'Opcion 2', value: this.form.option2 }, { name: 'Situación', value: this.form.situation }, { name: 'Contactar', value: this.form.time }, { name: 'Mensaje', value: this.form.message }, { name: 'Reference Link', value: window.location.href }, { name: 'Aceptación Privacidad RGDP:', value: this.form.checked }]
                })
                .then(response => {
                    if (response.status === 200) {
                        this.sentSuccess = true;
                    }
                })
                .catch(error => {
                    this.sentFailed = true;
                    this.sentSuccess = false;
                    console.log(error);
                });
        }
    }
};
</script>

<style scoped lang="scss">
.wrapper {
	.agente.contact-form .send-success {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		text-align: center;
		height: 100%;
	}
	.mask {
		position: absolute;
		background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(80, 177, 177, 0.5) 100%) !important;
		z-index: 1 !important;
		width: 100%;
		height: 86%;
		top: 20.5%;
	}
	#myVideo {
		margin-bottom: -10px;
		position: relative;
		z-index: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100vh;
		object-fit: cover;
		align-self: center;
	}
	.content {
		border-radius: 0 50px 50px 0;
		padding: 10px 5px 10px 20px;
		position: absolute;
		z-index: 99;
		margin-top: -90px;
		max-height: calc(100vh - 50px - 80px);
	}
	#myBtn {
		width: 7vh;
		height: 7vh;
		border-radius: 3em;
		font-size: 1rem;
		top: calc(50% - 0.5em);
		border: none;
		background: darkslategray;
		color: #fff;
		cursor: pointer;
	}
	#myBtn:hover {
		background: #ddd;
		color: black;
	}
	.contact-form {
		margin-top: -130px;
		background-color: rgba(0, 0, 0, 0.8);
		text-align: left;
		padding: 20px;
		@media only screen and (max-width: 768px) {
			margin-bottom: 50px;
		}

		h2 {
			width: 100%;
			font-family: LatoWebBold, sans-serif;
			color: whitesmoke;
			font-size: 1.5rem;
		}

		.field {
			width: 100%;
			border-radius: 0px;
			margin-bottom: 10px;
		}

		.drop {
			width: 100%;
			border-radius: 0px;
			margin-bottom: 10px;
			background-color: white !important;
		}

		p {
			text-align: left;
			color: whitesmoke;
		}

		.check {
			text-align: left;
			color: white;
		}

		.list {
			text-align: left;
			color: whitesmoke;
			list-style: square;
		}

		@media only screen and (max-width: 424px) {
			margin-top: -50px;
		}
	}
	.right-text {
		h1 {
			color: #009090;
			font-family: LatoWebBlack, sans-serif;
			font-size: 2.5rem;
			letter-spacing: 2px;
			@media only screen and (max-width: 426px) {
				font-size: 2.5rem;
			}
		}

		h2 {
			font-family: LatoWebLightItalic, sans-serif;
		}

		p {
			font-family: LatoWebSemiboldItalic, sans-serif;
			font-size: 1.5rem;
			color: #3d3d3d;
		}
	}
	.text-container {
		text-align: center;
		width: 100%;
		height: 100%;
		margin: 0 auto;
		padding: 80px 35px;
		border-top: #2a89b9 10px solid;
		background-color: white;
		-webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
		-moz-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
		box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);

		h1 {
			font-family: LatoWebBlack, sans-serif;
			font-size: 1.5rem;
			color: #4b4b4b;
		}

		h2 {
			font-family: LatoWebLightItalic, sans-serif !important;
			font-size: 1.2rem;
		}

		@media only screen and (max-width: 424px) {
			padding: 50px;
			margin-top: 0;
			width: 100%;
		}
		@media only screen and (max-width: 776px) {
			margin-top: 0;
			width: 100%;
		}
	}
	.sect3 {
		background-color: #334f7a;
		.left {
			position: relative;
			z-index: 0;
			img {
				max-width: 600px;
				width: 80vw;
				min-width: 200px;
				@media only screen and (max-width: 766px) {
					display: block;
					margin: 0 auto 50px auto;
				}
			}
		}
		.right {
			position: relative;
			z-index: 99;
			background-color: #1e365c;
			padding: 150px 100px;
			margin-top: 200px;
			h3 {
				font-family: LatoWebBold, sans-serif;
				color: white;
			}
			@media only screen and (max-width: 766px) {
				margin: 0 15px;
				padding: 100px 50px;
			}
			@media only screen and (max-width: 424px) {
				margin: 0 15px;
				padding: 50px 25px;
			}
		}
	}
	.sect4 {
		.gallery {
			position: relative;
			width: 100%;
		}

		.image {
			display: block;
			width: 100%;
			height: 100%;
		}

		.overlay {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			height: 100%;
			width: 100%;
			opacity: 0;
			transition: 0.5s ease;
			background-color: #009090;
		}

		.container:hover .overlay {
			opacity: 0.8;
		}

		.text {
			color: white;
			font-size: 20px;
			position: absolute;
			top: 50%;
			left: 50%;
			-webkit-transform: translate(-50%, -50%);
			-ms-transform: translate(-50%, -50%);
			transform: translate(-50%, -50%);
			text-align: center;
		}
	}
	.p-light {
		font-family: LatoWebLight, sans-serif;
		color: white;
	}
	.p-bold {
		font-family: LatoWebMedium, sans-serif;
		color: white;
	}
	.h1-grey {
		text-align: center;
		font-family: LatoWebBlack, sans-serif;
		color: #a7a7a7;
		font-size: 2.5vw;
		@media only screen and (max-width: 766px) {
			font-size: 3rem;
		}
	}
	.h1-sign {
		text-align: center;
		font-family: LatoWebBlack, sans-serif;
		color: #a7a7a7;
		font-size: 2.5vw;
		@media only screen and (max-width: 766px) {
			font-size: 3rem;
		}
	}
}
</style>
